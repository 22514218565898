/*global $*/

import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';

class MatrixContent
{
    constructor() 
    {
        this.galleries = $('.matrix-block.gallery .light-gallery');
        this.initLightGalleries();
    }

    initLightGalleries()
    {
        $.each(this.galleries, function (i, gallery) {
            lightGallery(gallery, {
                plugins: [lgZoom]
            });
        });
    }
}

export default MatrixContent;