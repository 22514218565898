/*global $*/

import SconePalace from './components/sconepalace';
import Slick from './components/slick';
import EventTracking from './components/event-tracking';
import Form from './components/form';
import Gallery from './components/gallery';
import News from './components/news';
import MatrixContent from './components/matrix-content';

window.Form = Form;
window.Gallery = Gallery;
window.News = News;

$(function() {
    window.SconePalace = new SconePalace();
    new Slick();
    new EventTracking;
    new MatrixContent();
});
