/*global $ formidable*/

class Form
{
    constructor(id, messages, scrollToMessage = true){
        this.form = $('#'+id);
        this.messages = messages;
        this.scrollToMessage = scrollToMessage;
        if (this.form.length) {
            this.initFormSubmit(id);
            console.log('Form ' + id + ' initialized');
        }
    }

    initFormSubmit(id) {
        let _this = this;
        this.form.off('submit');
        this.form.submit(function(e) {
            e.preventDefault();
            _this.messages.find('.alert-success').hide();
            _this.messages.find('.alert-danger').remove();
            if (formidable.validateForm(this, e)) {
                $(this).find('[type=submit]').attr('disabled', true);
                let url = $(this).attr('action');
                let form = $(this);
                let formData = new FormData(this);
                $.ajax({
                    url: url,
                    data: formData,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    dataType: 'JSON'
                }).done((data) => {
                    if (data.success) {
                        if (id == 'contact_us_form') {
                            dataLayer.push({'event':'contact_form_submission'});
                        }
                        let success = _this.messages.find('.alert-success');
                        success.fadeIn();
                        form.trigger('reset');
                        if (_this.scrollToMessage) {
                            $('html, body').animate({ scrollTop: success.offset().top - 170 }, 500);
                        }
                    }
                }).fail((response) => {
                    for (let i in response.responseJSON.errors) {
                        let errors = response.responseJSON.errors[i];
                        for (let j in errors) {
                            $('<div class="contact-us-error alert alert-danger">'+errors[j]+'</div>').appendTo(_this.messages);
                        }
                        $('html, body').animate({ scrollTop: $('.form-messages').offset().top - 170 }, 500);
                    }
                }).always(() => {
                    window.googleV3Captcha.getCaptcha(form.attr('id')).then(function (token) {
                        form.find('[name=_recaptcha_response]').val(token);
                        form.find('[type=submit]').attr('disabled', false);
                    });
                });
            }
        });
    }
}

export default Form;