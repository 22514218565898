/*global $*/

class Slick {
    constructor() {
        console.log('Slick initialized');
        $('.slick-carousel').slick({
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true,
            speed: 1000,
        });
        $('.slick-carousel-cta-arched').slick({
            // centerMode: true,
            // centerPadding: '200px',
            slidesToShow: 3,
            focusOnSelect: true,
            dots: false,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        centerMode: true,
                        centerPadding: '30px',
                        slidesToShow: 1,
                    }
                }
            ]
        });
        $('.slick-carousel-cta-big').slick({
            autoplay: true,
            slidesToShow: 2,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        focusOnSelect: true,
                        centerMode: true,
                        centerPadding: '30px',
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        focusOnSelect: true,
                        centerMode: true,
                        centerPadding: '30px',
                        slidesToShow: 1,
                    }
                }
            ]
        });
        $('.slick-event-feed-list').slick({
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1
        });
        $('.slick-event-feed-main').slick({
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.slick-event-feed-images',
            rows: 0
        });
        $('.slick-event-feed-images').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            asNavFor: '.slick-event-feed-main',
            rows: 0
        });
        $('.slick-carousel-other-news').slick({
            autoplay: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        focusOnSelect: true,
                        centerMode: true,
                        centerPadding: '30px',
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        focusOnSelect: true,
                        centerMode: true,
                        centerPadding: '30px',
                        slidesToShow: 1,
                    }
                }
            ]
        });
        $('.slick-carousel-card-whatson').slick({
            autoplay: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        focusOnSelect: true,
                        centerMode: true,
                        centerPadding: '30px',
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        focusOnSelect: true,
                        centerMode: true,
                        centerPadding: '30px',
                        slidesToShow: 1,
                    }
                }
            ]
        });
        $('.testimonials-carousel').slick({
            slidesToShow: 1,
            slidesToScroll: 1
        });
    }
}

export default Slick;