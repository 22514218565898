/*global $*/

class News
{
    constructor(categoryId)
    {
        this.categoryId = categoryId;
        this.select = $('.select-news-category');
        this.articlesLoaded = 9;
        this.articlesContainer = $('.news-container');
        this.noResults = $('.no-results');
        this.hasMore = this.articlesContainer.find('.news-square').length == this.articlesLoaded;
        this.isLoading = false;
        this.initSelectCategory();
        this.initScroll();
        this.initPopState();
        console.log('News initialized');
    }

    initPopState()
    {
        let _this = this;
        window.addEventListener('popstate', function() {
            let query = window.location.search;
            query = query.replace('?', '').split('=');
            if (query.length > 1) {
                _this.categoryId = query[1];
                _this.loadCategory();
            }
        });
    }

    pushState(categoryId)
    {
        let url = window.location.origin + window.location.pathname + '?category=' + categoryId;
        history.pushState(null, null, url);
    }

    initScroll()
    {
        $(window).scroll(() => {
            if (!this.hasMore) {
                return;
            }
            var bottom = this.articlesContainer.position().top + this.articlesContainer.outerHeight(true);
            let scroll = $(window).scrollTop() + $(window).height();
            if (scroll > bottom && !this.isLoading) {
                this.loadArticles();
            }
        });
    }

    loadArticles()
    {
        this.isLoading = true;
        $.ajax({
            url: '/load-news',
            data: {
                offset: this.articlesLoaded,
                limit: 3,
                categoryId: this.categoryId
            }
        }).done((data) => {
            data = $(data);
            this.articlesContainer.append(data);
            this.articlesLoaded += 3;
            this.hasMore = data.filter('.news-article').length == 3;
            data.filter('.news-article').hide().fadeIn('fast');
            this.isLoading = false;
        });
    }

    initSelectCategory()
    {
        let _this = this;
        this.select.change(function () {
            if ($(this).val() == _this.categoryId) {
                return;
            }
            _this.categoryId = $(this).val();
            _this.pushState($(this).val());
            _this.loadCategory();
        });
    }

    loadCategory()
    {
        this.isLoading = true;
        this.hasMore = true;
        this.articlesContainer.html('');
        this.articlesLoaded = 0;
        $.ajax({
            url: '/load-news',
            data: {
                offset: 0,
                limit: 9,
                categoryId: this.categoryId
            }
        }).done((data) => {
            if (data.trim() == '') {
                this.noResults.show();
                this.articlesContainer.hide();
                this.hasMore = false;
            } else {
                data = $(data);
                this.articlesContainer.show();
                this.noResults.hide();
                this.articlesContainer.html(data);
                this.articlesLoaded = 9;
                this.hasMore = data.filter('.news-article').length == 9;
                data.filter('.news-article').hide().fadeIn('fast');
            }
            this.isLoading = false;
        });
    }
}

export default News;